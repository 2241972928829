import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Books = ({ pageContext }) => {
  const books = pageContext.books
  return (
    <Layout>
      <div className="well">
        <h1> TextBooks </h1>
      </div>

      <hr />

      <div className="book-list">
        {books.map(book => {
          return (
            <>
              <a href={`/books/${book.id}`} className="btn btn-block">
                <h3>{book.name}</h3>
              </a>
              <hr />
            </>
          )
        })}
      </div>
    </Layout>
  )
}

export default Books
